$values: ('flex-start' 'flex-end' 'center' 'baseline' 'stretch');
$what-to-align: ('self' 'items');

@each $item in $what-to-align {
  @if $item == 'self' {
    .align-self-auto {
      align-self: auto !important; } }

  @each $value in $values {
    .align-#{$item}-#{$value} {
      align-#{$item}: #{$value} !important; } } }
