// ----------------------------------------------------------------
// global variables
// ----------------------------------------------------------------

// bulma override variables
// https://bulma.io/documentation/customize/variables/
// -----------------------------------
// colors
$black: #000;
$black-bis: #121212;
$black-ter: #232323;
$grey-darker: #333333;
$grey-dark: #494949;
$grey: #666666;
$grey-light: #b5b5b5;
$grey-lighter: #644343;
$grey-lightest: #ededed;
$white-ter: #f3f3f3;
$white-bis: #f9f9f9;
$white-quater: #fbfbfb;
$white-quin: #dddddd;
$white: #ffffff;

// Set your colors
$primary: #cc0000;
$secondary: red;
$secondary-dark: rgb(118, 19, 19);
$web: #e00022;
$web-light: #ff1c3f;
$line: #00b900;
$line-light: #00d300;
$twitter: #1a95df;
$brand-chrome-primary: #cc0000;
$brand-goros-primary: #564213;
$brand-hermes-primary: #cc0000;
$brand-chanel-primary: #cc0000;
$brand-louis-vuitton-primary: #cc0000;

$custom-colors: (
    "grey-darker": (
        $grey-darker
    ),
    "secondary": (
        $secondary
    ),
    "web": (
        $web
    ),
    "line": (
        $line
    ),
    "white-quater": (
        $white-quater
    ),
    "white-bis": (
        $white-bis
    ),
    "white-quin": (
        $white-quin
    )
);

$size-1: 3rem;
$size-2: 2.6rem; //36px
$size-3: 1.7rem; //24px
$size-4: 1.43rem; // 20px
$size-5: 1.15rem; //16px
$size-6: 1rem; //14px(default)
$size-7: 0.93rem; //13px
$size-8: 0.86rem; //12px

$size-small: $size-8;
$size-normal: $size-6;
$size-medium: $size-5;
$size-large: $size-4;

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 $size-8;

$dimensions: 16 24 32 48 60 90 140 150;

$border: $grey;

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 15px;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px;
// 960px container + 4rem
$desktop: 960px + (2 * $gap);
// 1152px container + 4rem
$widescreen: 980px + (2 * $gap);
$widescreen-enabled: true;
// 1344px container + 4rem
$fullhd: 1024px + (2 * $gap);
$fullhd-enabled: true;

// Typography
$family-sans-serif: "Noto Sans JP", sans-serif;
$family-primary: $family-sans-serif; // ja
$family-secondary: "Overpass"; // en

// body
$body-family: $family-sans-serif;
$body-size: 14px;
$body-color: $black;
$body-line-height: 1.75;

// hr
$hr-background-color: #ddd;
$hr-height: 1px;
$hr-margin: 25px 0;

// text
$text: $black;

// link
$link: $black;
$link-hover: $grey;

//control
$control-height: 46px;
$control-height-mobile: 36px;

$radius: 4px;

// coumn
$column-gap: 10px;

// block
$block-spacing: 3rem;

// navbar
$navbar-background-color: $white;
$navbar-item-hover-color: $secondary;
$navbar-item-hover-background-color: transparent;
$navbar-item-color: $text;
$navbar-burger-color: $text;

// menu
$menu-item-color: $primary;
$menu-item-radius: 0;
$menu-list-link-padding: 0.86em 38px;
$menu-label-font-size: 0.5em;

// breadcrumb
$breadcrumb-item-separator-color: $black;

//title
$title-size: 16px;
$title-color: $secondary;

// button
$button-color: $white;
$button-background-color: $black;
$button-border-color: $black;
$button-padding-vertical: 0.8em;
$button-padding-horizontal: 1em;
$button-hover-color: $white;
$button-hover-background-color: #555;
$button-hover-border-color: $button-hover-background-color;
$button-active-color: $white;
$button-active-background-color: #555;
$button-active-border-color: $button-hover-background-color;
$button-focus-color: $white;
$button-focus-background-color: #555;
$button-focus-border-color: $button-hover-background-color;
// $button-static-color: $text-light;
// $button-static-background-color: $scheme-main-ter;
$button-static-border-color: #ccc;
// $file-border-color: $border !default;
$file-radius: $radius;
$file-cta-background-color: $white;
// $file-cta-color: $text !default;
// $file-cta-hover-color: $text-strong !default;
// $file-cta-active-color: $text-strong !default;
$file-name-border-color: $grey;
// $file-name-border-style: solid !default;
// $file-name-border-width: 1px 1px 1px 0 !default;
// $file-name-max-width: 16em !default;

// tabs
$tabs-border-bottom-color: transparent;
$tabs-border-bottom-style: solid;
$tabs-border-bottom-width: 1px;
$tabs-link-color: $text;
$tabs-link-hover-border-bottom-color: $grey;
$tabs-link-hover-color: $white;
$tabs-link-active-border-bottom-color: $link;
$tabs-link-active-color: $link;
$tabs-link-padding: 0.85em 1em;

$tabs-boxed-link-radius: $radius;
$tabs-boxed-link-hover-background-color: $white;
$tabs-boxed-link-hover-border-bottom-color: $border;

$tabs-boxed-link-active-background-color: $grey;
$tabs-boxed-link-active-border-color: $border;
$tabs-boxed-link-active-border-bottom-color: transparent;

$tabs-toggle-link-border-color: $white;
$tabs-toggle-link-border-style: solid;
$tabs-toggle-link-border-width: 1px;
$tabs-toggle-link-hover-background-color: $white;
$tabs-toggle-link-hover-border-color: $white;
$tabs-toggle-link-radius: 0;
$tabs-toggle-link-active-background-color: $link;
$tabs-toggle-link-active-border-color: $white;
$tabs-toggle-link-active-color: $white;

// table
$table-background-color: transparent;
$table-cell-border: 0;
$table-cell-border-width: 0;

// tag
$tag-background-color: $black;
$tag-color: $white;
$tag-radius: 0;
$tag-delete-margin: 1px;

// input
$input-color: $black;
$input-background-color: $white;
$input-border-color: #ccc;
$input-height: $control-height;
$input-shadow: none;
$input-placeholder-color: #999999;
$input-hover-color: $input-color;
$input-hover-border-color: $input-border-color;
$input-focus-color: $input-color;
$input-focus-border-color: $grey;
$input-focus-box-shadow-size: none;
$input-focus-box-shadow-color: transparent;
$input-arrow: $link;
$input-radius: $radius;
$help-size: 1rem;
$control-border-width: 1px;
$control-line-height: 1.5;
$control-padding-vertical: calc(0.375em - #{$control-border-width});
$control-padding-horizontal: calc(1em - #{$control-border-width});

$radio-active-background-color: $input-border-color;
$radio-size: 30px;

$checkbox-active-background-color: $input-border-color;

$label-color: #333333;

$switch-active-background-color: $grey;
$switch-width: 65px;
$switch-height: 30px;

// modal
$modal-background-background-color: rgba(255, 255, 255, 0.75);

// box
$box-radius: 4px;
$box-shadow: none;
$box-color: $text;
$box-background-color: $white-ter;
$box-radius: 0;
$box-shadow: none;
$box-padding: 1.25rem;

// card$card-color: $text !default;
$card-background-color: $white;
$card-shadow: none;

// $card-header-background-color: transparent;
// $card-header-color: $text-strong;
// $card-header-padding: 0.75rem 1rem;
// $card-header-shadow: 0 0.125em 0.25em rgba($scheme-invert, 0.1);
// $card-header-weight: $weight-bold;

// $card-content-background-color: transparent;
// $card-content-padding: 40px;

// $card-footer-background-color: transparent;
// $card-footer-border-top: 1px solid $border-light;
// $card-footer-padding: 40px;

// pagenation
// $pagination-color: $text-strong !default;
// $pagination-border-color: $border !default;
// $pagination-margin: -0.25rem !default;
$pagination-min-width: 32px;

// $pagination-item-font-size: 1em !default;
// $pagination-item-margin: 0.25rem !default;
// $pagination-item-padding-left: 0.5em !default;
// $pagination-item-padding-right: 0.5em !default;

$pagination-hover-color: $white;
$pagination-hover-border-color: $black;

// $pagination-focus-color: $link-focus !default;
// $pagination-focus-border-color: $link-focus-border !default;

// $pagination-active-color: $link-active !default;
// $pagination-active-border-color: $link-active-border !default;

// $pagination-disabled-color: $text-light !default;
// $pagination-disabled-background-color: $border !default;
// $pagination-disabled-border-color: $border !default;

// $pagination-current-color: $link-invert !default;
// $pagination-current-background-color: $link !default;
// $pagination-current-border-color: $link !default;

// $pagination-ellipsis-color: $grey-light !default;

$pagination-shadow-inset: none;

// bulma helper variable
// -----------------------------------
$sizing-max-min-width-height-range-end: 500;

// header

// original variable
// -----------------------------------
$path: "../";
$path-images: #{$path}images;
$path-fonts: #{$path}fonts;

$body-size-mobile: 12px;
// header
$header-height: 80px;
$header-height-mobile: 40px;
$header-height-mobile2: 70px;

// footer
$footer-background-color: $primary;
$footer-padding: 0;
