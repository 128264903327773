$blur-light: 15px !default;
$blur-medium: 40px !default;
$blur-hard: 75px !default;

.is-blurred {
  filter: blur($blur-light) !important;

  &-medium {
    filter: blur($blur-medium) !important; }

  &-hard {
    filter: blur($blur-hard) !important; } }
