.flex {
  &-row {
    @include pure-flex;
    flex-direction: row !important; }

  &-column {
    @include pure-flex;
    flex-direction: column !important; } }

.nowrap {
  flex-wrap: nowrap !important; }

.wrap {
  flex-wrap: wrap !important; }

.wrap-reverse {
  flex-wrap: wrap-reverse !important; }

