$accordion-background-color: #d8edff ;
$accordion-radius: $radius ;
$accordion-icon-mergin-right : 16px;
$accordion-header-background-color: $white ;
$accordion-header-color: $black ;
$accordion-header-padding: 13px 23px;
$accordion-header-padding-touch: 8px 10px;
$accordion-button-color: $secondary ;
$accordion-content-border: 1px solid  $accordion-background-color;
$accordion-content-color: $secondary ;
$accordion-content-padding: 15px 23px ;
$accordion-content-padding-touch: $accordion-header-padding-touch ;
$accordion-content-radius: $radius ;
$accordion-content-pre-background-color: $white ;
$accordion-content-pre-code-background-color: transparent ;


.accordions {
  .accordion {
    @include block;
    display: flex;
    flex-direction: column;
    background-color: $accordion-background-color;
    strong {
      color: currentColor; }
    a:not(.button):not(.tag) {
      color: currentColor;
      text-decoration: underline; }

    &:not(:first-child) {
      .accordion-header {
        border-radius: 0; } }
    &:not(:last-child) {
      margin-bottom: 0;
      .accordion-content {
        border-radius: 0; } }

    .toggle,
    [data-action="toggle"] {
      cursor: pointer; }

    .accordion-header {
      display: flex;
      justify-content: space-between;
      padding: $accordion-header-padding;
      position: relative;
      background: $accordion-header-background-color;
      transition: all linear 0.5s;
      &:hover {
          background-color: #d8edff; }
      @include desktop {
        align-items: center; }
      @include touch {
        padding: $accordion-header-padding-touch; }
      &-icon {
        margin-right: $accordion-icon-mergin-right;
        @include touch {
          transform: scale(0.8); } }
      &-content {
        flex: 1;
        padding: 5px 0;
        @include touch {
          padding: 10px 0; } }
      &-button {
        margin-left: 1em;
        @include touch {
          padding-top: 10px;
          padding-right: 5px; } }

      .accordion-button {
        display: block;
        position: relative;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        width: 24px;
        height: 24px;
        flex-basis: 24px;
        min-width: 24px;
        @include touch {
          width: 16px;
          height: 16px;
          flex-basis: 16px;
          min-width: 16px; }
        &::before,
        &::after {
            content: '';
            display: block;
            height: 2px;
            width: 100%;
            top: 50%;
            left: 0;
            background: $accordion-button-color;
            transition: all linear 0.1s;
            position: absolute; }
        &::before {
            transform: rotate(0deg); }
        &::after {
            transform: rotate(90deg); } }

      & + .accordion-body {
        overflow-y: hidden;
        max-height: 0;
        color: $accordion-content-color;
        border: $accordion-content-border;
        border-radius: $accordion-content-radius;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: none;
        transition: max-height .2s ease; } }

    .accordion-content {
        padding: $accordion-content-padding;
        display: flex;
        @include touch {
          padding: $accordion-content-padding-touch; }
        &-icon {
            margin-right: $accordion-icon-mergin-right; }
        &-content {
            flex: 1;
            @include desktop {
              padding: 10px 0; }
            @include touch {
              padding: 10px 0; } }
        .icon {
            @include touch {
              transform: scale(0.8); } }
        code,
        pre {}
        background-color: $accordion-content-pre-background-color;
        pre code {}
        background-color: $accordion-content-pre-code-background-color; }
    &.is-active {
      .accordion-header {
        background-color: $secondary-dark;
        color: $white;
        .accordion-button {
            &::before {}
            &::after {
                background-color: $white; }
            &::before {
                transform: rotate(180deg); }
            &::after {
                transform: rotate(0); } } }
      .accordion-body {
        max-height: 100em; } } } }

.accordions .accordion:last-child .accordion-header+.accordion-body {
    border-bottom: none; }
