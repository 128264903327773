@if $enable-media-queries and $enable-flex-media-queries {
  @if $enable-flex-align-self-items-media-queries {
    $values: ('flex-start' 'flex-end' 'center' 'baseline' 'stretch');
    $what-to-align: ('self' 'items');

    @each $item in $what-to-align {
      @if $item == 'self' {
        @include mq('align-self-auto') {
          align-self: auto !important; } }

      @each $value in $values {
        @include mq('align-#{$item}-#{$value}') {
          align-#{$item}: #{$value} !important; } } } } }
