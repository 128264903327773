@if $enable-media-queries and $enable-flex-media-queries {
  @if $enable-flex-core-media-queries {
    @include mq('flex') {
      @include pure-flex; }

    @include mq('flex-row') {
      @include pure-flex;
      flex-direction: row !important; }

    @include mq('flex-column') {
      @include pure-flex;
      flex-direction: column !important; }

    @include mq('nowrap') {
      flex-wrap: nowrap !important; }

    @include mq('wrap') {
      flex-wrap: wrap !important; }

    @include mq('wrap-reverse') {
      flex-wrap: wrap-reverse !important; } } }
