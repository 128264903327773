$modifiers: ('height', 'width');

@each $modifier in $modifiers {
  .is-full-#{$modifier} {
    #{$modifier}: 100% !important; }

  .has-page-#{$modifier} {
    @if ($modifier == 'height') {
      #{$modifier}: 100vh !important; }
    @else if $modifier == 'width' {
      #{$modifier}: 100vw !important; } }

  .is-half-#{$modifier} {
    #{$modifier}: 50% !important; }

  .has-half-page-#{$modifier} {
    @if ($modifier == 'height') {
      #{$modifier}: 50vh !important; }
    @else if $modifier == 'width' {
      #{$modifier}: 50vw !important; } }

  .is-quarter-#{$modifier} {
    #{$modifier}: 25% !important; }

  .has-quarter-page-#{$modifier} {
    @if ($modifier == 'height') {
      #{$modifier}: 25vh !important; }
    @else if $modifier == 'width' {
      #{$modifier}: 25vw !important; } } }

