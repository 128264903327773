@mixin mq($className) {
	@include mobile {
		@include suffix($className, 'mobile') {
			@content; } }
	@include tablet {
		@include suffix($className, 'tablet') {
			@content; } }
	@include tablet-only {
		@include suffix($className, 'tablet-only') {
			@content; } }
	@include touch {
		@include suffix($className, 'touch') {
			@content; } }
	@include desktop {
		@include suffix($className, 'desktop') {
			@content; } }
	@include desktop-only {
		@include suffix($className, 'desktop-only') {
			@content; } }
	@include widescreen {
		@include suffix($className, 'widescreen') {
			@content; } }
	@include widescreen-only {
		@include suffix($className, 'widescreen-only') {
			@content; } }
	@include fullhd {
		@include suffix($className, 'fullhd') {
			@content; } } }

@mixin suffix($className, $suffix) {
	.#{$className}-#{$suffix} {
		@content; } }
