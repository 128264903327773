// spacing helpers settings
$range-start: $spacing-range-start;
$range-end: $spacing-range-end;
$interval: $spacing-interval;
$sides: (top, bottom, left, right);

$current: $range-start;
@while $current < $range-end {
  .has-margin-#{$current} {
    margin: #{$current}px !important; }

  .has-padding-#{$current} {
    padding: #{$current}px !important; }

  @each $side in $sides {
    .has-margin-#{$side}-#{$current} {
      margin-#{$side}: #{$current}px !important; }

    .has-padding-#{$side}-#{$current} {
      padding-#{$side}: #{$current}px !important; } }

  $current: $current + $interval; }

@each $side in $sides {
  .is-#{$side}-marginless {
    margin-#{$side}: 0 !important; }

  .is-#{$side}-paddingless {
    padding-#{$side}: 0 !important; } }

.has-margin-auto {
  margin: auto !important; }

@each $side in $sides {
  .has-margin-#{$side}-auto {
    margin-#{$side}: auto !important; } }
